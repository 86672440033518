<script lang="ts" setup>
import { type SliderList } from '@/utils/types'

const props = defineProps({
  lists: {
    type: Array
  },
  direction: {
    // 動畫方向
    type: String,
    default: 'up'
  },
  isGoNext: {
    // 切換方式是true: next, false: prev
    type: Boolean,
    default: true
  },
  timer: {
    // 動畫輪播時間
    type: Number,
    default: 3000
  },
  start: {
    // 從第幾個開始
    type: Number,
    default: 0
  }
})

const emit = defineEmits(['update:slidechange'])

const imgLists: Ref<SliderList[]> = ref<SliderList[]>(props.lists as SliderList[])

const prevIndex = ref(props.start === 0 ? imgLists.value.length - 1 : props.start - 1) // prev
const currentIndex = ref(props.start) // current
const nextIndex = ref(props.start + 1) // next

let setTimerInterval = setInterval(() => {
  slideNextChange()
}, props.timer)

const clearTimerInterval = () => {
  clearInterval(setTimerInterval)
}

//設定 slider
const setSlider = () => {
  imgLists.value.forEach((item: any) => {
    item['type'] = ''
  })
  imgLists.value[currentIndex.value]['type'] = 'carousel-current' //current
  imgLists.value[prevIndex.value]['type'] = 'carousel-prev' //prev
  if (imgLists.value.length > 1) {
    imgLists.value[nextIndex.value]['type'] = 'carousel-next' //next
  }
}

// next 輪播切換
const slideNextChange = () => {
  // 如果只有一張圖片就不輪播
  if (imgLists.value.length === 1) return

  clearTimerInterval()
  setTimerInterval = setInterval(() => {
    slideNextChange()
  }, props.timer)
  prevIndex.value = currentIndex.value
  currentIndex.value = currentIndex.value + 1
  nextIndex.value = nextIndex.value + 1
  if (currentIndex.value === imgLists.value.length) {
    currentIndex.value = 0
  }
  if (nextIndex.value === imgLists.value.length) {
    nextIndex.value = 0
  }
  emit('update:slidechange', currentIndex.value)
  setSlider()
}

// prev 輪播切換
const slidePrevChange = () => {
  clearTimerInterval()
  setTimerInterval = setInterval(() => {
    slidePrevChange()
  }, props.timer)
  nextIndex.value = nextIndex.value - 1
  prevIndex.value = prevIndex.value - 1
  currentIndex.value = currentIndex.value - 1
  if (currentIndex.value === -1) {
    currentIndex.value = imgLists.value.length - 1
  }
  if (prevIndex.value === -1) {
    prevIndex.value = imgLists.value.length - 1
  }
  if (nextIndex.value === -1) {
    nextIndex.value = imgLists.value.length - 1
  }
  emit('update:slidechange', currentIndex.value)
  setSlider()
}

const slideGoTo = (index: number) => {
  clearTimerInterval()
  setTimerInterval = setInterval(() => {
    slideNextChange()
  }, props.timer)
  prevIndex.value = index - 1
  currentIndex.value = index
  nextIndex.value = index + 1
  if (prevIndex.value === -1) {
    prevIndex.value = imgLists.value.length - 1
  }
  if (nextIndex.value === imgLists.value.length) {
    nextIndex.value = 0
  }
  emit('update:slidechange', currentIndex.value)
  setSlider()
}

onMounted(() => {
  setSlider()
})

defineExpose({
  slideNextChange,
  slidePrevChange,
  slideGoTo
})
</script>

<template>
  <figure class="carousel" :class="[item.type, props.direction, props.isGoNext === true ? 'isNext' : 'isNext']"
    v-for="(item, index) in imgLists" :key="index">
    <Nimg :src="item.pic" :alt="item.title" format="webp" />
  </figure>
</template>

<style lang="scss" scoped>
.carousel {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // transition: all 1s ease-in-out;
  transition: all 2s cubic-bezier(0.87, 0, 0.13, 1);

  &.up {
    &.isNext {
      &.carousel {
        &-current {
          transform: translateY(0);
          transition: transform 2s cubic-bezier(0.87, 0, 0.13, 1);
        }

        &-next {
          transform: translateY(100%);
          transition: transform 2s cubic-bezier(0.87, 0, 0.13, 1);
        }

        &-prev {
          transform: scale(2);
          transition: transform 2s cubic-bezier(0.87, 0, 0.13, 1);
        }
      }
    }

    &.isPrev {
      &.carousel {
        &-current {
          transform: translateY(0);
          transition: transform 2s cubic-bezier(0.87, 0, 0.13, 1);
        }

        &-prev {
          transform: translateY(100%);
          transition: transform 2s cubic-bezier(0.87, 0, 0.13, 1);
        }

        &-next {
          transform: scale(2);
          transition: transform 2s cubic-bezier(0.87, 0, 0.13, 1);
        }
      }
    }
  }

  &.left {
    &.isNext {
      &.carousel {
        &-current {
          transform: translateX(0);
          transition: transform 2s cubic-bezier(0.87, 0, 0.13, 1);
        }

        &-next {
          transform: translateX(-100%);
          transition: transform 2s cubic-bezier(0.87, 0, 0.13, 1);
        }

        &-prev {
          transform-origin: 50% 50%;
          transform: scale(2);
          transition: transform 2s cubic-bezier(0.87, 0, 0.13, 1);
        }
      }
    }

    &.isPrev {
      &.carousel {
        &-current {
          transform: translateX(0);
          transition: transform 2s cubic-bezier(0.87, 0, 0.13, 1);
        }

        &-prev {
          transform: translateX(-100%);
          transition: transform 2s cubic-bezier(0.87, 0, 0.13, 1);
        }

        &-next {
          transform: scale(2);
          transition: transform 2s cubic-bezier(0.87, 0, 0.13, 1);
        }
      }
    }
  }

  &-current {
    top: 0;
    z-index: 3;

    img {
      transform: scale(1) !important;
    }
  }

  &.isNext {
    &.carousel {
      &-prev {
        z-index: 2;
      }

      &-next {
        z-index: 1;
      }
    }
  }

  &.isPrev {
    &.carousel {
      &-prev {
        z-index: 1;
      }

      &-next {
        z-index: 2;
      }
    }
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // transform: scale(2);
    // transition: transform 2s cubic-bezier(0.87, 0, 0.13, 1);
  }
}
</style>
